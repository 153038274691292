<template>
  <b-card>

    <b-row>

      <!-- Org Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Org Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="orgData.logo_url"
            :text="avatarText(orgData.name)"
            size="100px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ orgData.name }}
              </h4>
              <span class="card-text">{{ orgData.full_name }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'myorg-edit' }"
                variant="primary"
              >
                Edit
              </b-button>

            </div>
          </div>
        </div>


      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="(orgData.enabled) ? 'success' : 'light-secondary'">
                {{ (orgData.enabled) ? 'Active' : 'Inactive' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              <img
                :src="`https://flagcdn.com/h20/${orgData.country}.png`"
                :srcset="`https://flagcdn.com/h40/${orgData.country}.png 2x,
                  https://flagcdn.com/h60/${orgData.country}.png 3x`"
                height="16"
                :alt="getCountryName(orgData.country)"
                class="mr-50"
              >
              <span>{{ getCountryName(orgData.country) }}</span>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BButton, BAvatar, BRow, BCol, BLink, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useOrgsList from '../../org/orgs-list/useOrgsList'
import countries from '@/countries-data'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge, BCard, BButton, BRow, BCol, BAvatar, BLink, BTooltip,
  },
  methods: {
    closeDeleteTooltip() {
      this.$refs.deleteTooltip.$emit('close')
    },
    getCountryName(countryCode) {
      return countries.find(country => country.value === countryCode).label
    },
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const { resolveOrgRoleVariant } = useOrgsList()


    return {
      avatarText,
      resolveOrgRoleVariant,
    }
  },
}
</script>

<style>

</style>
