<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :org-options="orgOptions"
      :org-id="orgData.id"
      :country="orgData.country"
      @refetch-data="refetchData"
    />
    <b-card
      no-body
    >
      <div class="card-body">
        <div class="card-title d-flex justify-content-between align-items-center">
          <div>{{ orgData.member_count }} Users</div>
          <b-button
            class="ml-2"
            variant="primary"
            @click="isAddNewUserSidebarActive = true"
          >
            <span class="text-nowrap">Add User</span>
          </b-button>
        </div>
        <div class="card-subtitle mb-3">{{ seatsAvailableString }}</div>
        <div class="card-text">
          <ul class="list-unstyled">
            <li v-for="user in usersData" :key="user.id" class="list-unstyled row border-bottom py-1 align-items-center">
              <b-media vertical-align="center" class="col">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="user.icon_url"
                    :text="avatarText(user.name)"
                    :variant="`light-${resolveUserRoleVariant(user.role)}`"
                    :to="{ name: 'myorg-user-edit', params: { id: user.id } }"
                  />
                </template>
                <b-link
                  :to="{ name: 'myorg-user-edit', params: { id: user.id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ user.name }}
                </b-link>
                <b-badge
                  v-if="user.enabled"
                  pill
                  :variant="`light-${resolveUserStatusVariant(user.enabled)}`"
                  class="text-capitalize"
                >
                  Active
                </b-badge>
                <b-badge
                  v-else
                  pill
                  :variant="`light-${resolveUserStatusVariant(user.enabled)}`"
                  class="text-capitalize"
                >
                  Inactive
                </b-badge>
              </b-media>
              <div class="col text-nowrap">
                {{ user.email }}
              </div>
              <div class="col text-nowrap">
                <feather-icon
                  :icon="resolveUserRoleIcon(user.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(user.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ user.role }}</span>
              </div>
              <div class="col">
                <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item :to="{ name: 'myorg-user-edit', params: { id: user.id } }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <div v-if="sessionUserData.id !== user.id">
                    <b-dropdown-item-button @click="openDeleteModal(user, deleteTargetItem)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </div>
            </li>
          </ul>
        </div>
      </div>
  </b-card>
    <b-modal id="modalDelete">
      <template #modal-header="{}">
        <h5>Confirm deletion</h5>
      </template>

      Are you sure you want to delete the user {{ deleteTargetItem.name }}?

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="primary"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="deleteTarget(deleteTargetItem.id); cancel();"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BLink, BBadge, BButton, BDropdown, BDropdownItemButton, BDropdownItem, BModal,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import useUsersList from '@/views/user/users-list/useUsersList'
import { avatarText } from '@core/utils/filter'
import UserListAddNew from '@/views/user/users-list/UserListAddNew.vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BModal,
    BDropdownItem,
    BDropdownItemButton,
    BDropdown,
    BButton,
    BCard,
    BLink,
    BBadge,
    BMedia,
    BAvatar,
    UserListAddNew,
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    seatsAvailableString() {
      if (this.orgData.max_members === 0) return 'Unlimited seats available'

      const availableSeats = this.orgData.max_members - this.orgData.member_count
      return `${availableSeats < 0 ? 0 : availableSeats} seats remaining out of ${this?.orgData?.max_members} total`
    },
  },
  methods: {
    openDeleteModal(item, targetRef) {
      // Update modal data then open it
      targetRef.id = item.id
      targetRef.name = item.name
      this.$bvModal.show('modalDelete')
    },
  },
  setup(props) {
    const usersData = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const orgOptions = ref([])
    const sessionUserData = ref({})
    sessionUserData.value = useJwt.getUserData()

    const { roleOptions } = useUsersList()

    const {
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      refetchData,
    } = useUsersList()

    const toast = useToast()


    const deleteTargetItem = ref({ id: null, name: null })

    const deleteTarget = targetId => {
      store.dispatch('app/deleteUser', { id: targetId })
        .then(() => {
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: `ERROR: ${error.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }


    store.dispatch('app/fetchOrgMembers', { id: props.orgData.id, queryParams: { perPage: 100 } })
      .then(response => { usersData.value = response.data.results })
      .catch(error => {
        if (error.response.status === 404) {
          usersData.value = undefined
        }
      })

    return {
      isAddNewUserSidebarActive,
      usersData,
      roleOptions,
      orgOptions,
      deleteTargetItem,
      sessionUserData,
      deleteTarget,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      avatarText,
      refetchData,
    }
  },
}
</script>

<style>

</style>
